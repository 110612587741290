import * as React from "react";

import TextField from "@mui/material/TextField";

export function CustomNote(props) {
	const [note, setNote] = React.useState(props.initialNote);

	function handleNoteChange(event) {
		let newNote = event.target.value;
		if (newNote !== null) {
			if (newNote !== note) {
				setNote(newNote);
				props.handleNoteChange(props.targetName, newNote);
			}
		}
	}

	return <TextField fullWidth label="Notitie" value={note} onChange={handleNoteChange} />;
}
